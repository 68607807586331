"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    Import: 'Importar',
    import: {
        preview: 'Previsualizar y completar datos',
        files: {
            XuntaParcelsXml: 'Parcelas desde XML Xunta/PAC',
            XuntaCattleCsv: 'Ganado desde CSV Xunta',
            XuntaCattleAddExploitationCSV: 'Añadir datos desde CSV de explotación'
        },
        sections: {
            parse: 'Interpretando fichero',
            preparing: 'Preparando datos',
            inserting: 'Insertando datos',
            finished: 'Importación finalizada'
        },
        errors: {
            invalidFileFormat: 'Formato de fichero inválido',
            missingSigpacEnclosure: 'No se encontró la referencia SIGPAC {reference} se salta este recinto.',
            multiplePACRegistries: 'El documento contiene varias solicitudes, consulte a su proveedor para importarlo',
            productForEnclosureNotFound: 'Producto no encontrado: {prodId}/{varId}',
            unknownWithRef: 'Error desconocido al importar el recinto {reference}',
            unknown: 'Error desconocido importando el fichero',
            motherNotFound: 'La madre de {eartag} no existe'
        },
        warnings: {
            alreadyExistingCattle: 'Las siguientes cabezas ya existen: {eartags}.'
        }
    }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parcelBatchesService = void 0;
const lodash_1 = require("lodash");
const EntityService_1 = require("./generic/EntityService");
const ExploitationEntityService_1 = require("./generic/ExploitationEntityService");
const _parcelBatchesService = new ExploitationEntityService_1.ExploitationEntityService('parcelbatches');
const _parcelBatchesParcelService = new EntityService_1.EntityService('exploitations/:exploitationId/parcelbatches/:batchId/parcels');
exports.parcelBatchesService = {
    list(exploitationId) {
        return _parcelBatchesService.list(exploitationId);
    },
    async save(exploitationId, batch) {
        const old = batch.id
            ? (await _parcelBatchesService.list(exploitationId)).find(o => o.id === batch.id)
            : undefined;
        // TODO: make transactional with API work
        if (batch.id) {
            await _parcelBatchesService.update(exploitationId, batch.id, batch);
        }
        else {
            const created = await _parcelBatchesService.create(exploitationId, batch);
            batch.id = created.id;
        }
        // Remove deleted
        if (old) {
            const oldIds = old.parcels.map(p => p.id);
            const newIds = batch.parcels.map(p => p.id).filter(lodash_1.identity);
            await Promise.all(oldIds
                .filter(oi => !newIds.includes(oi))
                .map(deletedId => _parcelBatchesParcelService.delete(deletedId, { exploitationId })));
        }
        await Promise.all(batch.parcels.map(p => p.id
            ? _parcelBatchesParcelService.update(p.id, p, { exploitationId, batchId: batch.id })
            : _parcelBatchesParcelService.create(p, { exploitationId, batchId: batch.id })));
    },
};

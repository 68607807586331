"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFiscalId = void 0;
const DNI_REGEX = /^(\d+)([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
exports.validateFiscalId = (fiscal_id) => {
    if (fiscal_id.match(DNI_REGEX)) {
        return isValidDNI(fiscal_id);
    }
    // FIXME: seems not to validate Aleutos CIF correctly, check the validation logic
    const fiscal_match = fiscal_id.match(CIF_REGEX);
    if (fiscal_match) {
        const letter = fiscal_match[1], number = fiscal_match[2], control = fiscal_match[3];
        let even_sum = 0;
        let odd_sum = 0;
        let n;
        for (let i = 0; i < number.length; i++) {
            n = parseInt(number[i], 10);
            if (i % 2 === 0) {
                n *= 2;
                odd_sum += n < 10 ? n : n - 9;
            }
            else {
                even_sum += n;
            }
        }
        let control_digit = (10 - Number((even_sum + odd_sum).toString().substr(-1)));
        let control_letter = 'JABCDEFGHI'.substr(control_digit, 1);
        if (letter.match(/[ABEH]/)) {
            return control === control_digit;
        }
        else if (letter.match(/[KPQS]/)) {
            return control === control_letter;
        }
        else {
            return control === control_digit || control === control_letter;
        }
    }
    if (fiscal_id.match(NIE_REGEX)) {
        let nie_prefix = fiscal_id.charAt(0);
        switch (nie_prefix) {
            case 'X':
                nie_prefix = 0;
                break;
            case 'Y':
                nie_prefix = 1;
                break;
            case 'Z':
                nie_prefix = 2;
                break;
        }
        return isValidDNI(nie_prefix + fiscal_id.substr(1));
    }
    return false;
};
const isValidDNI = (dni) => {
    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);
    return letter === dni.charAt(8);
};

import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Blocker, BlockerProps } from './Blocker';

export type ModalCloseOrigin = 'button' | 'background';

export interface ModalCloseEvent {
  origin: ModalCloseOrigin;
}

type onBeforeCloseHandler = (e: ModalCloseEvent) => boolean | Promise<boolean>;

export interface ModalProps {
  /**
   * Places the given class on the element.
   */
  className?: string;
  /**
   * Sets the content of the modal dialog.
   */
  children?: React.ReactNode;
  /**
   * Determines if the modal is currently open or not.
   */
  open?: boolean;
  /**
   * Event triggered before the modal is actually closed by the user.
   */
  onBeforeClose?: onBeforeCloseHandler;
  /**
   * Event triggered when the modal should be closed by the user.
   */
  onClose?(e: ModalCloseEvent): void;
  /**
   * When specified, default max-width of 500px will be overridden
   * @deprecated Please define through styled components and ${Modal.inner.ModalContent}
   */
  width?: string;
  /**
   * Specify the minimal height for the modal container
   * @deprecated Please define through styled components and ${Modal.inner.StyledModal}
   */
  minWidth?: string;
}

const openAnimationDuration = 300;
const closeAnimationDuration = 200;
const blockerAnimationDuration = 200;

const InAnimation = (startOffset: number) => keyframes`
  from {
    opacity: 0;
    /* transform: translate(0, ${startOffset}px); */
  }
  to {
    opacity: 1;
    /* transform: translate(0px); */
  }
`;

const OutAnimation = () => keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const BlockerInAnimation = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export interface ModalProps {
  width?: string;
}

export interface StyledModalProps extends ModalProps {
  closing: boolean;
}

const StyledModal = styled('div')<StyledModalProps>(
  ({ width, closing, theme }) => css`
    outline: none;
    color: black;
    ${width ? `width: ${width}` : ''};
    margin: ${theme.spacing(3)}px auto;
    display: flex;
    align-items: center;
    animation: ${closing ? OutAnimation() : InAnimation(-72)}
      ${closing ? closeAnimationDuration : openAnimationDuration}ms cubic-bezier(0, 0, 0.25, 1);
    animation-fill-mode: forwards;
    @media screen and (max-width: ${width || '500px'}) {
      width: 100%;
      min-height: 100%;
      margin: 0;
      align-items: stretch;
    }
  `,
);

export interface StyledBlockerProps extends BlockerProps {
  closing: boolean;
}

const StyledBlocker = styled(Blocker)<StyledBlockerProps>(
  ({ closing }) => css`
    animation: ${closing ? OutAnimation() : BlockerInAnimation()} ${blockerAnimationDuration}ms
      cubic-bezier(0, 0, 0.25, 1);
    animation-fill-mode: forwards;
  `,
);

export interface ModalContentProps {
  minWidth?: string;
}

const ModalContent = styled.div<ModalContentProps>(
  ({ minWidth, theme }) => css`
    /* margin-top: ${({ theme }) => theme.layout.titleHeight}; */
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
    border-top: 4px solid ${theme.palette.primary.main};
    ${minWidth ? `min-width: ${minWidth}` : ''};
    max-height: calc(100% - ${theme.spacing(3)}px);
  `,
);

interface ModalState {
  closing: boolean;
}

/**
 * A simple modal dialog for requiring user interaction.
 */
export class Modal extends React.PureComponent<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      closing: false,
    };
  }

  private closeFrom(origin: ModalCloseOrigin) {
    const { onClose, onBeforeClose } = this.props;
    const closeEvent = { origin };

    this.callOnBeforeCloseHandler(onBeforeClose, closeEvent).then(closePermitted => {
      if (!closePermitted) {
        return;
      }
      this.setState({ closing: true }, () =>
        setTimeout(() => {
          if (typeof onClose === 'function') {
            onClose(closeEvent);
          }
          this.setState({ closing: false });
        }, closeAnimationDuration),
      );
    });
  }

  private callOnBeforeCloseHandler(onBeforeClose: onBeforeCloseHandler | undefined, closeEvent: ModalCloseEvent) {
    if (typeof onBeforeClose !== 'function') {
      return Promise.resolve(true);
    }
    const canClose = onBeforeClose(closeEvent);
    return Promise.resolve(canClose);
  }

  private closeBackground = () => {
    this.closeFrom('background');
  };

  render() {
    const { onBeforeClose: _0, children, onClose, open = false, minWidth, ...rest } = this.props;
    const { closing } = this.state;

    return (
      open && (
        <StyledBlocker closing={closing} onClose={this.closeBackground}>
          <StyledModal tabIndex={0} closing={closing} {...rest}>
            <ModalContent minWidth={minWidth}>
              {children}
            </ModalContent>
          </StyledModal>
        </StyledBlocker>
      )
    );
  }
}

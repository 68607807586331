import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { SWCard } from '../components/generic/Card';
import { BaseLayout } from '../components/layout/BaseLayout';
import { LoginForm } from '../components/LoginForm';
import { getUser } from '@smartwood/common-client/services/app.service';
import { parseQuery } from '../utils/router';

export const LoginPage = withRouter(({ location }) => {
  const history = useHistory();
  const redirectWhenLogged = () => {
    const query = parseQuery(location);
    const returnUrl = query.get('returnUrl');
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      history.replace('/notebook');
    }
  };
  if (getUser()) redirectWhenLogged();
  return (
    <BaseLayout>
      <SWCard>
        <LoginForm onLogin={redirectWhenLogged} />
      </SWCard>
    </BaseLayout>
  );
});
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachedEntityService = void 0;
class CachedEntityService {
    constructor(base, options) {
        this.base = base;
        this.maxCacheTime = 24 * 60 * 60 * 1000;
        this.cacheRefreshTime = 30000;
        this.cache = [];
        if (options) {
            if (options.maxTime)
                this.maxCacheTime = options.maxTime;
            if (options.refreshTime)
                this.cacheRefreshTime = options.refreshTime;
        }
    }
    get(key, params) {
        return this.getFromCache(String(key), () => this.base.get(key, params));
    }
    async create(data, params) {
        return this.base.create(data, params).then(d => {
            // this.addToCache((<any>data).id, d);
            this.removeListsFromCache();
            return d;
        });
    }
    update(key, data, params) {
        return this.base.update(key, data, params).then(d => {
            const cacheId = String(key);
            this.removeFromCache(cacheId);
            this.removeListsFromCache();
            // this.addToCache(cacheId, d);
            return d;
        });
    }
    delete(key, params) {
        return this.base.delete(key, params).then(() => {
            const cacheId = String(key);
            this.removeFromCache(cacheId);
            this.removeListsFromCache();
        });
    }
    list(params) {
        const cacheKey = this.getCacheKeyFromParams(params);
        return this.getFromCache(cacheKey, () => this.base.list(params));
    }
    clearCache(key) {
        this.cache = key ? this.cache.filter(i => i.id !== key) : [];
    }
    getCacheKeyFromParams(params) {
        if (params) {
            return Object.keys(params).sort()
                .map(k => `${k}:${params[k]}`)
                .join(',');
        }
        return '';
    }
    removeFromCache(key) {
        this.cache = this.cache.filter(({ id }) => key !== id);
    }
    removeListsFromCache() {
        // @ts-ignore
        this.cache = this.cache.filter(({ data }) => typeof data.then === 'function' || !Array.isArray(data));
    }
    addToCache(id, data) {
        const entry = { id, data, date: +new Date() };
        this.cache.push(entry);
        return entry;
    }
    async getFromCache(key, getFn) {
        const now = +new Date();
        this.cache = this.cache.filter(({ date }) => date + this.maxCacheTime >= now);
        const cacheHit = this.cache.find(ci => ci.id === key);
        if (cacheHit) {
            return cacheHit.data;
        }
        else {
            const item = getFn();
            // Store promise so other methods will return it while not resolved
            const cache = this.addToCache(key, item);
            // Get rid of unused Promise once fullfilled
            cache.data = await item;
            return item;
        }
    }
}
exports.CachedEntityService = CachedEntityService;

import React from 'react';
import { useValidation, ValidableFieldProps } from './validation';
import { NestedSelectProps, NestedSelect } from '../generic/NestedSelect';

export const SWNestedSelect = ({ field, validations, validateWith, label, helperText, ...props }: ValidableFieldProps & SWNestedSelectProps) => {
  const { error, isRequired } = useValidation({ field, validations, validateWith });
  if (isRequired) label += ' *';
  console.log({ error, isRequired });
  return (
    <NestedSelect
      label={label}
      error={!!error}
      helperText={error || helperText}
      {...props}
    />
  );
};

interface SWNestedSelectProps extends Pick<NestedSelectProps, 'data' | 'onChange' | 'value'> {
  label: string;
  helperText?: string;
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityService = void 0;
const app_service_1 = require("../app.service");
class EntityService {
    constructor(basePath) {
        this.basePath = basePath;
        this.params = basePath.split('/').filter(c => c.startsWith(':')).map(c => c.substr(1));
        if (!this.params.length)
            delete this.params;
    }
    get(key, params) {
        return app_service_1.http.get(this.formatUrl(params) + '/' + key);
    }
    list(params) {
        return app_service_1.http.get(this.formatUrl(params));
    }
    create(data, params) {
        return app_service_1.http.post(this.formatUrl(params), data);
    }
    update(key, data, params) {
        return app_service_1.http.put(this.formatUrl(params) + '/' + key, data);
    }
    delete(key, params) {
        return app_service_1.http.delete(this.formatUrl(params) + '/' + key);
    }
    formatUrl(params = {}) {
        const baseUrl = app_service_1.config.serverURL + '/' + this.basePath;
        if (this.params) {
            let result = baseUrl;
            for (const pk of this.params) {
                if (!params[pk])
                    throw 'Missing parameter ' + pk;
                result = result.replace(':' + pk, params[pk]);
            }
            return result;
        }
        return baseUrl;
    }
}
exports.EntityService = EntityService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const cattle_species_1 = require("./cattle_species");
// const cattleSpeciesGroups = Object.keys(cattleSpecies)
//   .reduce((p, s) => ({ ...p, [s]: cattleSpecies[s].name }), {});
const parcelForm = {
    title: 'Parcelas',
    new: 'Nueva parcela',
    edit: 'Editar parcela',
    filter: 'Filtrar parcelas',
    parcel_num: 'Número de parcela agrícola',
    sigpac: 'Referencia SIGPAC (Escriba los números seguidos)',
    name: 'Nombre',
    production: 'Producto',
    declared_area: 'Superficie',
};
const notebook = {
    exploitationListTitle: 'Explotaciones',
    parcelListTitle: 'Parcelas',
    exploitationTitle: 'Explotación',
    parcel: 'Parcela',
    batch: 'Lote',
    cattleunit: 'Cabeza',
    title: 'Libreta de pastoreo',
    sections: {
        cattle: 'Gestión de ganado',
        currentExploitation: 'Explotación actual: ',
        economics: 'Economía',
        exploitations: 'Explotaciones',
        feeding: 'Alimentación',
        forest: 'Sección forestal',
        history: 'Históricos alimentación',
        logout: 'Cerrar sesión',
        adminView: 'Panel de administrador',
        parcels: 'Gestión de parcelas',
        reproduction: 'Cubrición y partos',
        treatments: 'Tratamientos',
        workforce: 'Mano de obra',
        infrastructure: 'Infraestructura',
    },
    contact: {
        title: 'Mensaje al técnico',
        message: 'Mensaje',
    },
    documents: {
        list: 'Documentos',
        noDocuments: 'No hay documentos dados de alta',
        askProvider: 'Solicite a su proveedor el alta de nuevos documentos para poder generarlos',
        order: 'Encargar nuevo documento',
        queuedTitle: 'Documento solicitado',
        queuedMessage: 'Recibirá en unos minutos el documento solicitado en su email.',
        queuedError: 'Ha habido un problema solicitando el documento, por favor, inténtelo más tarde.',
        codes: {
            herd2018gal: 'Cuaderno pastoreo (Xunta)',
        },
    },
    grazing: {
        chooseParcel: 'Escoger parcela',
        cattle: 'Ganado',
        chooseCattle: 'Escoger ganado',
        actions: {
            editDay: 'Editar entrada',
        },
    },
    batches: {
        filter: 'Filtrar lotes',
        bathcesParcelListTitle: 'Administracion de parcelas y lotes',
        newBatch: 'Nuevo lote',
        nameRequired: 'El nombre no puede estar vacío',
    },
    parcelSelection: {
        title: 'Elegir parcela',
    },
    cattle: {
        units: 'Ganado',
        batches: 'Lotes',
        specie: cattle_species_1.default,
    },
    cattleSelection: {
        title: 'Elegir ganado',
    },
    modalDialog: {
        title: 'No existen más registros',
    },
    history: {
        title: 'Gestión de histórico',
        tabs: {
            herdDay: 'Pastoreo',
        },
    },
    workforce: {
        units: 'Mano de Obra',
    },
    economics: {
        title: 'Economía',
        subtotal: 'Subtotal',
        net_earnings: 'Ganancias netas',
        balance: 'Balance',
        incomes: {
            title: 'Ingresos',
            new: 'Nuevo ingreso',
            edit: 'Editar ingreso',
            choose_cattle: 'Elegir ganado',
            types: {
                cattle_sell: 'Venta de ganado',
                grant: 'Ayuda o bolsa',
                production_sell: 'Venta de producto',
            },
            form: {
                type: 'Tipo',
                date: 'Fecha',
                value: 'Valor',
                concept: 'Concepto',
                period: 'Periodo',
                extra: { concept: 'Concepto' },
                cattle_sell: {
                    title: 'Datos de venta',
                    type: 'Tipo de venta',
                    facilitator: 'Facilitador',
                    destination: 'Destino',
                    send_date: 'Fecha envío',
                    alive_weight: 'Peso vivo (g)',
                    channel_weight: 'Peso canal (g)',
                },
            },
        },
        expenses: {
            title: 'Gastos',
            new: 'Nuevo gasto',
            edit: 'Editar gasto',
            types: {
                cattle_feed: 'Alimento ganado',
                treatment: 'Tratamiento',
                contracts: 'Subcontrata',
                maintenance: 'Mantenimiento',
                insurance: 'Seguro',
                payroll: 'Nómina',
                tax: 'Impuesto',
            },
            form: {
                type: 'Tipo',
                date: 'Fecha',
                value: 'Coste',
                extra: {
                    amount: 'Cantidad',
                    concept: 'Concepto',
                    input: 'Ínsumo',
                    medicine: 'Medicamento',
                    period: 'Periodo',
                    ss_id: 'Identificación de Seg. Social',
                    invoice_number: 'Número de factura',
                },
            },
        },
        income_cattle_sell: {
            title: 'Cabezas vendidas',
            types: {
                alive: 'Vivo',
                meat: 'Carne',
                rearing: 'Recría',
            },
            facilitators: {
                cooperative: 'Cooperativa',
                dealer: 'Tratante',
            },
            destinations: {
                slaughterhouse: 'Matadero',
                dealer: 'Tratante',
                self_consume: 'Autoconsumo',
            },
        },
    },
    reproduction: {
        births: {
            title: 'Partos',
        },
        reproduction: {
            title: 'Cubrición',
        },
    },
    exploitation: {
        new: 'Nueva explotación',
        edit: 'Editar explotación',
        fiscal_id: 'NIF',
        rega: 'Rega',
        name: 'Nombre',
        representative_nif: 'CIF/NIF del representante',
        representative_phone: 'Teléfono del representante',
        representative_mobile: 'Móvil del representante',
        reproduction_method: 'Metodo de reproducción',
        cooperative_email: 'Correo de cooperativa',
        reproduction_methods: {
            inside_alone: 'Interior individual',
            inside_group: 'Interior conjunto',
            outside: 'Exterior',
        },
        species: {
            title: 'Especies de ganado',
            all: 'Todas las especies',
            filter: 'Filtrar por especie',
            select: 'Seleccionar especie',
        },
    },
    feeding: {
        grazing: {
            title: 'Histórico de pastoreo',
            form: {
                new: 'Nuevo día de pastoreo',
                edit: 'Editar día de pastoreo',
                export: 'Exportar documentos',
                date: 'Día',
                exploitation_cattlebatch_name: 'Ganado',
                exploitation_parcel_name: 'Parcela(s)',
                exploitation_cattlebatch_id: 'Ganado',
            },
        },
    },
    forms: {
        treatment: {
            title: 'Tratamientos',
            new: 'Nuevo tratamiento',
            edit: 'Editar tratamiento',
            date: 'Fecha',
            is_disease: 'Es enfermedad',
            disease_id: 'Enfermedad',
            treatment: 'Tratamiento',
            result: 'Resultado',
            result_date: 'Fecha fin/resultado',
            cattle: 'Ganado (lote o cabeza)',
            observations: 'Observaciones',
        },
        grazingParcel: {
            exploitation_parcel: parcelForm,
            fodder: 'Aprovechamiento forrajero',
            pasture_area: 'Superficie declarada pastos',
            activity: 'Actividad agraria',
            activities: {
                pasture: 'Pasto',
                production: 'Productivo',
            },
        },
        parcel: parcelForm,
        parcelBatch: {
            title: 'Lotes de parcelas',
            new: 'Nuevo lote de parcelas',
            edit: 'Editar lote de parcelas',
            name: 'Nombre del lote',
            count: '# parcelas',
            list: 'Parcelas',
            start_day: 'Primer día',
            last_day: 'Último día',
        },
        cattleUnit: {
            new: 'Nueva cabeza de ganado',
            edit: 'Editar cabeza de ganado',
            name: 'Nombre',
            eartag: 'Crotal',
            specie_id: 'Especie',
            subspecie: 'Raza',
            birth: 'Nacimiento',
            death: 'Muerte',
            filters: {
                selectState: 'Seleccione el estado',
                state: 'Estado',
                dead: 'Muertos',
                selled: 'Vendidos',
                inExploitation: 'En explotación',
                age: 'Edad',
            },
            in_date: 'Entrada',
            out_date: 'Salida',
            bornHere: 'Nació aquí',
            origin_exploitation: 'Explotación origen',
            pickSpecie: 'Escoger especie',
            sex: 'Sexo',
            sexs: {
                chooseSex: 'Seleccione el sexo',
                F: 'Hembra',
                M: 'Macho',
            },
        },
        cattleBatch: {
            new: 'Nuevo lote de ganado',
            edit: 'Editar lote',
            addUnit: 'Añadir cabeza',
            newBatch: 'Nuevo lote',
            list: 'Lista de ganado',
            name: 'Nombre del lote',
            type: 'Tipo de lote',
            start_day: 'Primer día',
            last_day: 'Último día',
            data: {
                reproduction_method: 'Método cubrición',
            },
            types: {
                null: 'Otros',
                reproduction: 'Reproducción',
            },
            reproduction_methods: {
                male_effect: 'Efecto macho',
                sponge: 'Esponjas',
                melatonin: 'Implante melatonina',
                male_always: 'Macho siempre presente',
            },
        },
        reproduction: {
            new: 'Nuevo evento de cubrición',
            edit: 'Editar cubrición',
            start_date: 'Fecha inicio',
            end_date: 'Fecha fin',
            observations: 'Observaciones',
            cattlebatch_id: 'Lote de reproducción',
            choose_male: 'Elegir machos',
            add_male: 'Añadir macho',
            males: 'Machos',
        },
        birth: {
            new: 'Nuevo parto',
            edit: 'Editar parto',
            date: 'Fecha',
            method: 'Método',
            mother_id: 'Madre',
            father_id: 'Padre',
            observations: 'Observaciones',
            add_calve: 'Añadir cría',
            methods: {
                inside_alone: 'Interior individual',
                inside_group: 'Interior grupal',
                outside: 'Exterior',
            },
            calves: 'Crías',
            calve: {
                id: 'Id',
                sex: 'Sexo',
                weight: 'Peso',
                observations: 'Observaciones',
                deathbirth: 'Nace muerto',
                eartag: 'Crotal',
                name: 'Nombre',
                specie_id: 'Especie',
            },
        },
        workforceUnit: {
            new: 'Nueva mano de obra',
            edit: 'Editar',
            extra: {
                name: 'Nombre',
                fiscal_id: 'CIF/NIF',
                type: 'Tipo',
                working_hours: 'Jornada',
                start_date: 'Fecha inicio',
                end_date: 'Fecha fin',
            },
            types: {
                family: 'Familia',
                contractor: 'Autónomo',
                employee: 'Asalariado',
                laborer: 'Jornalero',
            },
        },
    },
    infrastructure: {
        title: 'Infraestructura',
        machinery: {
            title: 'Maquinaria',
            new: 'Nueva maquinaria',
            edit: 'Editar maquinaria',
            types: {
                tractor: 'Tractor',
                automotive: 'Automotriz',
                tool: 'Apero',
                other: 'Otros',
            },
            form: {
                extra: {
                    type: 'Tipo',
                    name: 'Nombre',
                    description: 'Descripción',
                    observations: 'Observaciones',
                },
            },
        },
        buildings: {
            title: 'Edificaciones',
            new: 'Nueva edificación',
            edit: 'Editar edificación',
            types: {
                stable: 'Nave',
                storage: 'Almacén',
                pit: 'Fosa',
                dunghill: 'Estercolero',
                other: 'Otro',
            },
            form: {
                extra: {
                    type: 'Tipo',
                    name: 'Nombre',
                    description: 'Descripción',
                    observations: 'Observaciones',
                },
            },
        },
    },
    cattleTreatment: {
        antibiotic: 'Anbitiótico',
        vaccine: 'Vacuna',
        sanitation: 'Medidas higiénicas',
        disinsection: 'Desinsectación',
        deworming: 'Desparasitación',
        disinfection: 'Desinfección',
        euthanasia: 'Eutanasia',
    },
    cattleTreatmentResult: {
        death: 'Muerte',
        healing: 'Curación',
        controlled: 'Controlada',
        chronic: 'Cronificada',
        with_injury: 'Con lesión visible',
    },
    cattleDisease: {
        border_abort: 'Aborto border',
        campilobacter_abort: 'Aborto campilobacter',
        chlamydia_abort: 'Aborto clamideas',
        brucella_abort: 'Aborto por brucella',
        q_feber_abort: 'Aborto por fiebre Q',
        samonella_abort: 'Aborto por salmonelas',
        toxoplasma_abort: 'Aborto toxoplasma',
        enzoo_nasal_adenoc: 'Adenocarcinoma nasal enzootico',
        enzoo_pulm_adenoc: 'Adenocarcinoma pulmonar ovino',
        agalaxia_cont: 'Agalaxia contagiosa',
        arthritis: 'Artrítis',
        osteoarthritis: 'Osteoartritis',
        aujeszky: 'Aujeszky/Pseudorrabia',
        belfo: 'Belfo/Picón',
        border_disease: 'Border disease',
        bronchitis: 'Bronquitis',
        bursite: 'Bursite',
        thicks: 'Garrapatas',
        blindness: 'Ceguera',
        cenurosis: 'Cenurosis',
        aplomos_defect: 'Defecto aplomos',
        caries: 'Caries',
        bacterial_dermatitis: 'Dermatitis bacteriana',
        dermatophilosis: 'Dermatofilosis',
        dichrocelliosis: 'Dicroceliosis',
        ectima: 'Ectima contagioso',
        abscess: 'Enf. de los abcesos',
        parasit_enteritis: 'Enteritis parasitaria',
        enterotoxemia: 'Enterotoxemia',
        orchitis: 'Epididimitis/Orquitis',
        scald: 'Escaldadura',
        spikelet_conjunctiva: 'Espiguilla en conjuntiva',
        fascioliosis: 'FAsciolisis',
        photosensitization: 'Fotosensibilización',
        fractures: 'Fracturas',
        gas_gangrene: 'Gangrena gaseosa',
        hematuria: 'Hematuria',
        hernia_abdominal: 'Hernia abdominal',
        hernia_inguinal: 'Hernia inguinal',
        impetigo: 'Impétigo',
        '3rd_eyelid_inf': 'Inflamación tercer párpado',
        hoof_laminitis: 'Laminitis pezuña',
        caseous_lymphaden: 'Linfadenitis caseosa',
        bluetongue: 'Lengua azul',
        listeriosis: 'Listeriosis',
        'maedi-visna': 'Maedi-Visna',
        mastitis_clinical: 'Mamitis clínica',
        mastitis_gangrenous: 'Mamitis gangrenosa',
        mastitis_subclinic: 'Mamitis subclínica',
        megaesophagus: 'Megaesófago',
        metritis: 'Metritis',
        myasis: 'Miasis',
        pneumonia_bacteria: 'Pneumonía bacteriana',
        pneumonia_parasyte: 'Pneumonía parasitaria',
        oestrus_ovis: 'Oestrosis',
        papilloma: 'Papiloma',
        facial_paralysis: 'Parálisis facial',
        paratuberculosis: 'Paratuberculosis',
        pedero: 'Pedero',
        pelodera: 'Pelodera',
        lices: 'Piojos',
        polyarthritis: 'Poliartritis',
        prolapse_uterine: 'Prolapso uterino',
        prolapse_vaginal: 'Prolapso vaginal',
        fleas: 'Pulgas',
        sunburn: 'Quemaduras solares',
        keratoconjunctivitis: 'Qeratoconjutivitis infeciosa',
        hydatidic_cyst: 'Quite hidatídico',
        rhinitis: 'Rinitis',
        scabies: 'Sarna',
        scrapie: 'Scrapie/tembladera',
        tetanus: 'Tétanos',
        tympanism: 'Timpanismo',
        tinea: 'Tiña',
        toxaemia_gestational: 'Toxemia gestacional',
        tuberculosis: 'Tuberculosis',
        Urolithiasis: 'Urolitiasis',
    },
};
exports.default = notebook;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappedExploitationEntityService = exports.MappedEntityService = void 0;
const lodash_1 = require("lodash");
const ExploitationEntityService_1 = require("./ExploitationEntityService");
/**
 * TF: Frontend datatype
 * TB: Backend datatype
 */
class MappedEntityService {
    constructor(base, fromAPI, toAPI) {
        this.base = base;
        this.fromAPI = fromAPI;
        this.toAPI = toAPI;
    }
    async create(d, params) {
        return this.base.create(await this.toAPI(d), params).then(this.fromAPI);
    }
    get(k, params) {
        return this.base.get(k, params).then(this.fromAPI);
    }
    list(params) {
        return this.base.list(params).then(l => Promise.all(l.map(this.fromAPI)));
    }
    async update(k, d, params) {
        return this.base.update(k, await this.toAPI(d), params).then(this.fromAPI);
    }
    delete(k, params) {
        return this.base.delete(k, params);
    }
    clearCache(key) {
        if ('clearCache' in this.base)
            this.base.clearCache(key);
        else
            throw new TypeError('Missing clearCache for base ' + this.constructor.name);
    }
}
exports.MappedEntityService = MappedEntityService;
class MappedExploitationEntityService extends ExploitationEntityService_1.ExploitationEntityService {
    constructor(basePath, fromAPI, toAPI = lodash_1.identity, onChanges = lodash_1.noop) {
        super(basePath, onChanges);
        this.base = new MappedEntityService(this.base, fromAPI, toAPI);
    }
}
exports.MappedExploitationEntityService = MappedExploitationEntityService;

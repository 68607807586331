import React, { Suspense, lazy } from 'react';
import './App.css';
import { ThemeProvider as MUIThemeProvider, StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { swTheme } from './theme';
import { LoginPage } from './pages/LoginPage';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthRoute } from './utils/router';
import { GlobalAlerts } from './components/layout/GlobalAlerts';
import { CircularProgress } from '@material-ui/core';
import { SessionProvider } from './components/layout/SessionContext';

const ResetPasswordPage = lazy(() => import(/* webpackChunkName: "recover" */ './pages/ResetPasswordPage'));
const AdminRouter = lazy(() => import(/* webpackChunkName: "admin" */ './pages/AdminRouter'));
const NotebookRouter = lazy(() => import(/* webpackChunkName: "notebook" */ './pages/NotebookRouter'));

const App = () =>
  <StylesProvider injectFirst>
    <ThemeProvider theme={swTheme}>
      <MUIThemeProvider theme={swTheme}>
        <SnackbarProvider maxSnack={5} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
          <SessionProvider>
            <Router>
              <Suspense fallback={<CircularProgress />}>
                <Switch>
                  <Route path='/admin/:section?'>
                    <AuthRoute>
                      <AdminRouter />
                    </AuthRoute>
                  </Route>
                  <Route path='/notebook/:exploitationId?/:section?'>
                    <AuthRoute>
                      <NotebookRouter />
                    </AuthRoute>
                  </Route>
                  <Route path='/reset'>
                    <ResetPasswordPage />
                  </Route>
                  <Route path='/'>
                    <LoginPage />
                  </Route>
                  <LoginPage />
                </Switch>
              </Suspense>
              <GlobalAlerts />
            </Router>
          </SessionProvider>
        </SnackbarProvider>
      </MUIThemeProvider>
    </ThemeProvider>
  </StylesProvider>;

export default App;

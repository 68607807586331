"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileFormat = exports.InvalidFileFormat = exports.InvalidAuthTokenError = exports.InvalidCredentialsError = exports.ExpiredAuthError = exports.ResponseError = void 0;
/**
 * Generic non specific error response container
 */
class ResponseError extends Error {
    constructor(response, inner) {
        super(response.data || response.statusText || response.status);
        this.inner = inner;
        this.data = response.data;
        this.code = response.status && Number(response.status);
    }
}
exports.ResponseError = ResponseError;
class ExpiredAuthError extends Error {
    constructor(email) {
        super('Expired auth data');
        this.email = email;
    }
}
exports.ExpiredAuthError = ExpiredAuthError;
class InvalidCredentialsError extends Error {
    constructor() {
        super('Invalid credentials');
    }
}
exports.InvalidCredentialsError = InvalidCredentialsError;
class InvalidAuthTokenError extends Error {
    constructor() {
        super('Invalid or missing auth data');
    }
}
exports.InvalidAuthTokenError = InvalidAuthTokenError;
class InvalidFileFormat extends Error {
    constructor(expected, nested) {
        super('Invalid format' + expected ? 'expecting ' + expected : '');
        this.expected = expected;
        this.nested = nested;
    }
}
exports.InvalidFileFormat = InvalidFileFormat;
var FileFormat;
(function (FileFormat) {
    FileFormat["XML"] = "xml";
})(FileFormat = exports.FileFormat || (exports.FileFormat = {}));

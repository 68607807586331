import React from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, Select as MUISelect, MenuItem, SelectProps, FormHelperText } from '@material-ui/core';
import { ValidableFieldProps, useValidation } from './validation';

export const Select = ({ label, onChange, children, helperText, className, ...props }: SelectProps & { label?: string, onChange, helperText?: string; }) => {
  const id = `select-${label}`;
  return (
    <FormControl className={className}>
      {label && <InputLabel id={id} error={props.error}>{label}</InputLabel>}
      <MUISelect
        labelId={label && id}
        id={`select-${id}`}
        onChange={e => onChange(e.target.value)}
        {...props}
      >
        {children}
      </MUISelect>
      {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const SWSelect = ({ field, validations, validateWith, label, options, helperText, ...props }:
  ValidableFieldProps & SelectProps & { label: string, helperText?: boolean, options: { label: string, value: any; }[]; }) => {
  const { error, value, updateField, isRequired } = useValidation({ field, validations, validateWith });
  if (isRequired) label += ' *';
  return <Select
    label={label}
    value={value || ''}
    helperText={error || helperText}
    onChange={v => updateField(field, v)}
    error={error}
    {...props}
  >
    {options.map(o => o.value != null
      ? <MenuItem key={o.label} value={o.value}>{o.label}</MenuItem>
      : <EmptySelectOption key='empty' value={o.value}>{o.label}</EmptySelectOption>)}
  </Select>;
};

export const EmptySelectOption = styled(MenuItem)`
  color: gray;
  font-style: italic;
`;

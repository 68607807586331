const pad = (number: number) => number < 10 ? '0' + number : number;

export const toISOShort = (d: Date) => d && d.getUTCFullYear() + '-' + pad(d.getUTCMonth() + 1) + '-' + pad(d.getUTCDate());

export const getDatesInBetween = (targetDates: any[]) => {
  const startDate = new Date(targetDates[0]);
  let endDate = new Date(targetDates[(targetDates.length - 1)]);
  const dates: Date[] = [];
  let currentDate: Date = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1));
  endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, 0));
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates.map(r => toISOShort(r));
};

const addDays = (targetDate: Date, days) => {
  const date = new Date(Date.UTC(targetDate.getUTCFullYear(), targetDate.getUTCMonth(), targetDate.getUTCDate()));
  date.setDate(date.getDate() + days);
  return date;
};
import React from 'react';
import { t } from '@smartwood/common-client/utils/translations';
import { EventEmitter } from '@smartwood/common-client/utils/EventEmitter';
import { SectionTitle } from '../components/generic/SectionTitle';
import { SWButton } from '../components/Button';
import { SectionFooter } from '../components/generic/sections';

export const modalMessages = new EventEmitter<ModalMessageOptions>();

export interface ModalMessageOptions {
  Content: React.ElementType; // TODO try to refactor into React.ComponentType
}

export const globalModal = {
  alert(options: AlertOptions) {
    return new Promise<void>(res => {
      const Content: React.ElementType = () => <AlertContent {...options} onClose={res} />;
      modalMessages.fire({ Content });
    });
  }
};

interface AlertOptions {
  title?: string;
  message: string;
}

const AlertContent = ({ title, message, onClose }: AlertOptions & { onClose: () => void; }) =>
  <>
    <SectionTitle title={title || t('Alert')} />
    <SectionFooter>
      <label>{message}</label>
    </SectionFooter>
    <SectionFooter>
      <SWButton onClick={() => { modalMessages.fire(); onClose(); }}>{t('OK')}</SWButton>
    </SectionFooter>
  </>;

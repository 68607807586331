import React, { Component } from 'react';
import styled from 'styled-components';
import { t } from '@smartwood/common-client/utils/translations';
import { ICattleSpecieData, cattleSpecies } from '@smartwood/common-client/services/notebook.service';
import { TextField, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { Select, EmptySelectOption } from './Select';
import { Autocomplete } from '@material-ui/lab';
import { useValidation, ValidableFieldProps } from './validation';

export class CattleSpecieField extends Component<SpecieFieldProps, SpecieFieldState> {

  constructor(props) {
    super(props);
    this.state = {
      species: undefined,
      groups: undefined,
    };
  }

  componentDidMount() {
    cattleSpecies.list().then(species => {
      const groups = Array.from(new Set(species.map(s => s.specie))) as string[];
      this.setState({ species, groups });
    });
  }

  render() {
    const { value, onChange, error } = this.props;
    const { species, groups } = this.state;
    const specie = value ? species?.find(s => s.id === value) : undefined;
    const subspecies = specie && species?.filter(s => s.specie === specie.specie);
    return <FormControl>
      <FormFieldContent>
        <Select
          value={specie?.specie || ''}
          onChange={v => {
            let generic = species?.find(s => s.specie === v && s.subspecie === 'generic');
            if (!generic) generic = species?.find(s => s.specie === v);
            onChange(generic?.id);
          }}
          label={t('notebook.forms.cattleUnit.specie_id')}
          error={!!error}
        >
          <EmptySelectOption key='' value=''>{t('Choose')}</EmptySelectOption>
          {groups?.map(g => <MenuItem key={g} value={g}>{t('notebook.cattle.specie.' + g + '.name')}</MenuItem>)}
        </Select>
        <Autocomplete
          id='specie-selector'
          disableClearable
          options={subspecies || []}
          value={specie || { id: 0, specie: '', subspecie: '' }}
          getOptionLabel={s => s.specie && s.subspecie && t(`notebook.cattle.specie.${s.specie}.${s.subspecie}`)}
          renderInput={params => <TextField {...params} label={t('notebook.forms.cattleUnit.subspecie')} error={!!error} />}
          onChange={(_e, i) => { onChange(i?.id); }}
        />
      </FormFieldContent>
      {error && <FormHelperText id='specie-selector' error>{error}</FormHelperText>}
    </FormControl>;
  }

};

const FormFieldContent = styled.div`
  display: flex;
  flex-flow: row;
  * { flex-grow: 1}
`;

export const SWCattleSpecieField = ({ field, validations, validateWith }: ValidableFieldProps) => {
  const { error, value, updateField } = useValidation({ field, validations, validateWith });
  const onUpdate = (ev) => updateField(field, ev);
  return (
    <CattleSpecieField
      value={value || null}
      error={error}
      onChange={onUpdate}
      helperText={error}
    />
  );
};

interface SpecieFieldProps {
  value: number | null;
  error: string;
  onChange: (v: number | null | undefined) => void;
  helperText?: string;
}

interface SpecieFieldState {
  species?: ICattleSpecieData[];
  groups?: string[];
}
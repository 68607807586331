import React, { Fragment } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { KeyCodes } from '../../utils/keycodes';

export interface BlockerProps {
  /**
   * Places the given class on the element.
   */
  className?: string;
  /**
   * Event emitted when the user wants to remove the blocker.
   */
  onClose?(e: React.SyntheticEvent<HTMLElement>): void;
}

const FixedContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  // https://material-ui.com/es/customization/z-index/
  z-index: 1250;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${transparentize(.4, '#404040')};
`;

const FocusKeeper = styled.a`
  height: 0;
  width: 0;
  overflow: hidden;
`;

/**
 * Defines a generic content blocking overlay element, e.g., for a modal dialog.
 */
export class Blocker extends React.Component<BlockerProps> {
  private modalNode: HTMLDivElement | null = null;

  private getFocusables(): NodeListOf<Element> | undefined {
    if (this.modalNode) {
      return this.modalNode.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
      );
    }

    return undefined;
  }

  private keepFocus(position: 'first' | 'last') {
    const focusables = this.getFocusables();

    if (focusables && focusables.length > 2) {
      const nextElement = (position === 'first' ? focusables[1] : focusables[focusables.length - 2]) as HTMLElement;
      nextElement.focus();
    }
  }

  private keepFirstFocus = () => {
    this.keepFocus('first');
  };

  private keepLastFocus = () => {
    this.keepFocus('last');
  };

  private setElement = (node: HTMLDivElement | null) => {
    if (node && node !== this.modalNode) {
      const el = node.querySelector('*[tabindex]') as HTMLElement;
      (el || node).focus();
    }

    this.modalNode = node;
  };

  private notifyClose(e: React.SyntheticEvent<HTMLElement>) {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      onClose(e);
    }
  }

  private onContainerClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (
      !this.onScrollbarClick(e) &&
      (e.target === e.currentTarget || (e.target instanceof HTMLElement && e.target.parentElement === e.currentTarget))
    ) {
      this.notifyClose(e);
    }
  };

  private onScrollbarClick = (e: React.MouseEvent<HTMLElement>) => {
    return e.target instanceof HTMLElement && e.target.offsetLeft + e.target.scrollWidth < e.clientX;
  };

  private onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.escape) {
      this.notifyClose(e);
    }
  };

  render() {
    const { children, onClose, ...props } = this.props;

    return (
      <Fragment>
        <FixedContainer
          ref={this.setElement}
          onMouseDown={this.onContainerClick}
          onKeyDown={this.onKeyPress}
          {...props}>
          <FocusKeeper href="#" onFocus={this.keepLastFocus} />
          {children}
          <FocusKeeper href="#" onFocus={this.keepFirstFocus} />
        </FixedContainer>
      </Fragment>
    );
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventEmitter = void 0;
class EventEmitter {
    constructor() {
        this.handlers = [];
    }
    on(fn) {
        this.handlers.push(fn);
        return fn;
    }
    disable(fn) {
        this.handlers = this.handlers.filter(h => h !== fn);
    }
    fire(data) {
        this.handlers.forEach(h => h(data));
    }
}
exports.EventEmitter = EventEmitter;

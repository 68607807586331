export const immutablePush = <T>(a: T[], i: T) => [...a, i];
export const immutableUnshift = <T>(a: T[], i: T) => [i, ...a];
export const immutableRemove = <T>(a: T[], i: T) => a.filter(b => b !== i);
export const immutableSwitch = <T>(a: T[], i: T, add: boolean) =>
  add ? immutablePush(a, i) : immutableRemove(a, i);
export const immutableReplace = <T>(a: T[], i: number, item: T) => {
  const result = [...a];
  result[i] = item;
  return result;
};
export const sumNumberArray = (a: number[]) => a.reduce((total, x) => total + x, 0);
import React, { useState, useRef, Fragment, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Modal } from './generic/Modal';
import { t } from '@smartwood/common-client/utils/translations';
import { login, recoverPassword } from '@smartwood/common-client/services/app.service';
import { SWForm, SWTextField } from './form/Form';
import { SectionTitle } from './generic/SectionTitle';
import { SWButton } from './Button';
import { SectionFooter, SectionBody } from './generic/sections';

export const LoginForm = ({ onLogin }: LoginFormProps) => {
  const [data, setData] = useState({ user: '', pass: '' });
  const [recoverPass, setRecoverPass] = useState(false);
  const passFieldRef = useRef<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const doLogin = useCallback(async (data) => {
    try {
      await login(data.user, data.pass);
      onLogin();
    } catch (er) {
      console.error(er);
      enqueueSnackbar(t('app.login.error'), { variant: 'error' });
      passFieldRef.current && passFieldRef.current.focus();
      setData({ user: data.user, pass: '' });
    }
  }, [setData, passFieldRef, onLogin, enqueueSnackbar]);

  const doRecoverPass = async () => {
    try {
      await recoverPassword(data.user);
      setRecoverPass(false);
      enqueueSnackbar(t('app.login.recoverPasswordDone'), { variant: 'success' });
    } catch (er) {
      console.error(er);
    }
  };

  return (
    <Fragment>
      <SWForm model={data} setModel={setData} onSubmit={doLogin}>
        <SectionTitle title={t('app.login.title')} />
        <SWTextField
          field='user'
          validations={['required']}
          label={t('app.login.form.email')}
        />
        <SWTextField
          field='pass'
          validations={['required']}
          inputRef={el => passFieldRef.current = el}
          type='password' label={t('app.login.form.password')}
        />
        <SWButton type='submit' color='primary' variant='contained'>{t('Enter')}</SWButton>
        <SWButton onClick={() => setRecoverPass(true)}>{t('app.login.recoverPassword')}</SWButton>
      </SWForm>
      <Modal open={recoverPass} onClose={() => setRecoverPass(false)}>
        <SWForm model={data} setModel={setData} onSubmit={doRecoverPass}>
          <SectionTitle title={t('app.login.recoverPassword')} />
          <SectionBody>
            <SWTextField
              autoFocus
              field='user'
              validations={['required']}
              label={t('app.login.form.email')}
            />
          </SectionBody>
          <SectionFooter>
            <SWButton onClick={() => setRecoverPass(false)}>{t('Cancel')}</SWButton>
            <SWButton type='submit' color='primary' variant='contained'>{t('Send')}</SWButton>
          </SectionFooter>
        </SWForm>
      </Modal>
    </Fragment>
  );
};


interface LoginFormProps {
  onLogin(): void;
}

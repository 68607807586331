import React, { useContext } from 'react';
import { t } from '@smartwood/common-client/utils/translations';
import { SectionFooter } from '../generic/sections';
import { SWButton } from '../Button';
import { FormContext } from './Form';

export const SWFormActions = ({ onCancel, onSave, isCreate }: FormActionsProps) => {
  const { saving } = useContext(FormContext);
  return <SectionFooter>
    <SWButton disabled={saving} onClick={onCancel}>{t('Cancel')}</SWButton>
    <SWButton
      loading={saving}
      type={onSave ? undefined : 'submit'}
      onClick={onSave}
      color='primary'
      variant='contained'>
      {t(isCreate ? 'Create' : 'Save')}
    </SWButton>
  </SectionFooter>;
};

interface FormActionsProps {
  onCancel: () => void;
  onSave?: () => void;
  isCreate?: boolean;
}
import { createMuiTheme } from '@material-ui/core/styles';

export const swTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#70b02d',
      contrastText: 'white',
    },
    secondary: {
      main: '#008f3f',
    },
    action: {
      // selected: "rgba(0, 0, 0, 0.14)",
      // disabledBackground: "rgba(0, 0, 0, 0.12)",
      // disabled: 'rgba(0, 0, 0, 0.26)',
      active: 'rgba(0, 0, 0, 0.9)'
    }
  },
  shape: {
    borderRadius: 4
  },
  layout: {
    titleHeight: '74px'
  }
});

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    layout: {
      titleHeight: React.CSSProperties['height'];
    };
  }
  interface ThemeOptions {
    layout?: {
      titleHeight?: React.CSSProperties['height'];
    };
  }
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExploitationEntityService = void 0;
const lodash_1 = require("lodash");
const CachedEntityService_1 = require("./CachedEntityService");
const EntityService_1 = require("./EntityService");
class ExploitationEntityService {
    constructor(basePath, onChanges = lodash_1.noop) {
        this.onChanges = onChanges;
        this.base = new CachedEntityService_1.CachedEntityService(new EntityService_1.EntityService('exploitations/:exploitationId/' + basePath));
    }
    get(exploitationId, id) {
        return this.base.get(id, { exploitationId });
    }
    async create(exploitationId, data) {
        const result = await this.base.create(data, { exploitationId });
        this.onChanges();
        return result;
    }
    async update(exploitationId, id, data) {
        const result = await this.base.update(id, data, { exploitationId });
        this.onChanges();
        return result;
    }
    list(exploitationId, params) {
        if (params)
            return this.base.list(Object.assign(Object.assign({}, params), { exploitationId }));
        else
            return this.base.list({ exploitationId });
    }
    async delete(exploitationId, id) {
        const result = await this.base.delete(id, { exploitationId });
        this.onChanges();
        return result;
    }
    clearCache(key) {
        this.base.clearCache(key);
    }
}
exports.ExploitationEntityService = ExploitationEntityService;

import styled from 'styled-components';

export const SectionBody = styled.div`
  overflow: auto;
  text-align: left;
  padding: 0 ${ ({ theme }) => `${theme.spacing(3)}px ${theme.spacing(1) / 2}px`};
  margin-bottom: auto;
`;

export const SectionFooter = styled.div`
  padding: ${ ({ theme }) => `${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`};
  text-align: right;
`;
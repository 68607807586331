import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const SWCard = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  &>* {
    margin-bottom: 10px;
  }
`;
import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div<{ disablePadding; }>`
  display: flex;
  align-items: center;
  position: relative;
  h3 {
    padding: ${({ theme, disablePadding }) => disablePadding ? 0 : `0 ${theme.spacing(2)}px`};
    width: 100%;
  }
  .actions {
    display: flex;
    &>button {
      font-size: 1.2rem;
    }
  }
`;

const renderAction = (action, i) => {
  return action.icon
    ? <IconButton key={i} onClick={action.onClick} title={action.tooltip}>
      <FontAwesomeIcon icon={action.icon} />
    </IconButton>
    : React.cloneElement(action.element, { key: i });
};

export const SectionTitle = ({ title, actions, disablePadding, className }: SectionTitleProps) =>
  <Container disablePadding={disablePadding} className={className}>
    <h3>{title}</h3>
    {actions && <div className='actions'>
      {actions.map(renderAction)}
    </div>}
  </Container>;

interface SectionTitleProps {
  title: string;
  actions?: ({ icon, onClick, tooltip?: string; } | { element: JSX.Element, tooltip?: string; })[];
  disablePadding?: boolean;
  className?: string;
}
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import { t } from '@smartwood/common-client/utils/translations';
import { ICattleSpecieData } from '@smartwood/common-client/services/notebook.service';
import { TextField, Popover, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Select, EmptySelectOption } from './Select';
import { Autocomplete } from '@material-ui/lab';
import { DummyTextField } from './TextField';

const WidthForce = styled.div`
  min-width: 200px;
  display: flex;
  flex-flow: column;
  padding: 5px;
`;

export const ShrinkSpecieField = ({ species, value, onChange, warning }: SpecieFieldProps) => {
  const [anchor, setAnchor] = useState<HTMLElement | undefined>();
  const specie = useMemo<ICattleSpecieData | null | undefined>(
    () => value != null ? species.find(s => s.id === value) : value,
    [value, species]);
  const groups = useMemo<string[]>(() => {
    if (!species) return [];
    return Array.from(new Set(species.map(s => s.specie)));
  }, [species]);
  const subspecies = useMemo<ICattleSpecieData[]>(() => {
    if (!specie) return species;
    return species.filter(s => s.specie === specie.specie);
  }, [species, specie]);
  return <>
    <DummyTextField
      warn={warning}
      onChange={noop}
      value={specie ? t(`notebook.cattle.specie.${specie.specie}.${specie.subspecie}`) : ''}
      onClick={e => setAnchor(e.currentTarget)}
      InputProps={{
        endAdornment: <FontAwesomeIcon icon={faPencilAlt} />
      }}
    />
    {!!anchor && <Popover
      open
      anchorEl={anchor}
      onClose={() => setAnchor(undefined)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <WidthForce>
        <Select
          value={specie?.specie || ''}
          onChange={v => {
            let generic = species.find(s => s.specie === v && s.subspecie === 'generic');
            if (!generic) generic = species.find(s => s.specie === v);
            onChange(generic?.id);
          }}
          label={t('notebook.forms.cattleUnit.specie_id')}
        >
          <EmptySelectOption key='' value=''>{t('Choose')}</EmptySelectOption>
          {groups.map(g => <MenuItem key={g} value={g}>{t('notebook.cattle.specie.' + g + '.name')}</MenuItem>)}
        </Select>
        <Autocomplete
          disableClearable
          options={subspecies}
          value={specie || undefined}
          getOptionLabel={s => t(`notebook.cattle.specie.${s.specie}.${s.subspecie}`)}
          renderInput={(params) => <TextField {...params} label={t('notebook.forms.cattleUnit.subspecie')} />}
          onChange={(_e, i) => { onChange(i?.id); setAnchor(undefined); }}
        />
      </WidthForce>
    </Popover>}
  </>;
};

interface SpecieFieldProps {
  value: number | null;
  onChange: (v: number | null | undefined) => void;
  warning?: boolean;
  species: ICattleSpecieData[];
}
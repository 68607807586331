import React, { useState, useEffect, useContext } from 'react';
import { IUser } from '@smartwood/common-client/models/user';
import { getUser, sessionEvents } from '@smartwood/common-client/services/app.service';

const SessionContext = React.createContext({} as SessionContextType);

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState<SessionContextType>({
    user: getUser()
  });

  useEffect(() => {
    sessionEvents.on(e => {
      setSession({ user: getUser() });
    });
  });
  return <SessionContext.Provider value={session} children={children} />;
};

export const useSession = () => useContext(SessionContext);

interface SessionContextType {
  user?: IUser;
}
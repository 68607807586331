"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRoles = exports.changePassword = exports.recoverPassword = exports.logout = exports.login = exports.initSession = exports.http = exports.init = exports.sessionEvents = exports.setUser = exports.getUser = exports.config = exports.API_REVISION = void 0;
const axios_1 = require("axios");
const formats_1 = require("../utils/formats");
const errors_1 = require("../models/errors");
const EventEmitter_1 = require("../utils/EventEmitter");
exports.API_REVISION = 3;
var Cookies;
(function (Cookies) {
    Cookies["token"] = "token";
    Cookies["user"] = "user";
})(Cookies || (Cookies = {}));
let storage;
let user;
const getToken = () => storage.get(Cookies.token);
exports.getUser = () => user;
exports.setUser = (u) => {
    user = u;
};
exports.sessionEvents = new EventEmitter_1.EventEmitter();
exports.init = (appConfig, str) => {
    exports.config = appConfig;
    storage = str;
    if (getToken()) {
        const cookie = storage.get(Cookies.user);
        user = cookie ? JSON.parse(cookie) : undefined;
        exports.sessionEvents.fire('authenticated');
    }
    // if (getStoredToken()) {
    // renew token for user retrieval
    // }
};
const getHeaders = () => {
    const token = getToken();
    return token && { Authorization: 'Bearer ' + token };
};
let pendingAuthOperations;
exports.http = {
    processPendingAuth() {
        if (pendingAuthOperations) {
            pendingAuthOperations.forEach(({ fn, res, rej }) => this.op(fn).then(res, rej));
            pendingAuthOperations = undefined;
        }
    },
    op(fn) {
        return new Promise((res, rej) => {
            if (pendingAuthOperations && !getToken()) {
                if (exports.config.debug)
                    console.log('Pending Auth operation');
                pendingAuthOperations.push({ fn, res, rej });
            }
            else {
                parseAxiosResponse(fn())
                    .then(res)
                    .catch(e => {
                    if (e instanceof errors_1.ExpiredAuthError) {
                        if (pendingAuthOperations) {
                            pendingAuthOperations.push({ fn, res, rej });
                            storage.set(Cookies.token);
                        }
                        else {
                            pendingAuthOperations = [{ fn, res, rej }];
                            if (exports.config.mobile) {
                                exports.initSession();
                            }
                            else {
                                exports.sessionEvents.fire('expired');
                            }
                        }
                    }
                    else
                        rej(e);
                });
            }
        });
    },
    get(url) {
        if (exports.config.debug) {
            console.log('GET ' + url);
        }
        return exports.http.op(() => axios_1.default.get(url, {
            responseType: 'json',
            headers: getHeaders(),
        }));
    },
    post(url, data) {
        if (exports.config.debug) {
            console.log('POST ' + url, data);
        }
        return exports.http.op(() => axios_1.default
            .post(url, formats_1.mapDates(data), {
            responseType: 'json',
            headers: getHeaders(),
        }));
    },
    put(url, data) {
        if (exports.config.debug) {
            console.log('PUT ' + url, data);
        }
        return exports.http.op(() => axios_1.default
            .put(url, formats_1.mapDates(data), {
            responseType: 'json',
            headers: getHeaders(),
        }));
    },
    delete(url) {
        if (exports.config.debug) {
            console.log('DELETE ' + url);
        }
        return exports.http.op(() => axios_1.default
            .delete(url, {
            responseType: 'json',
            headers: getHeaders(),
        }));
    },
};
const parseAxiosResponse = (axiosPromise) => axiosPromise.then(response => {
    exports.config.debug && console.log('Response', response);
    return response.data;
}, err => {
    console.error('Axios error', err);
    if (err.response) {
        let data = err.response.data;
        if (typeof data === 'string') {
            console.error('Error data string = ', data);
            switch (data) {
                case 'invalid_credentials':
                    throw new errors_1.InvalidCredentialsError();
                default:
                    throw new errors_1.ResponseError(err.response);
            }
        }
        if (data != null && Array.isArray(data.errors)) {
            if (data.errors.includes('invalid_auth: expired')) {
                throw new errors_1.ExpiredAuthError(user && user.email);
            }
            if (data.errors.includes('Missing or invalid auth data')) {
                throw new errors_1.InvalidAuthTokenError();
            }
            if (data.errors.some((e) => typeof e === 'string' && e.startsWith('invalid_auth:'))) {
                console.error('Invalid token', data.errors);
                throw new errors_1.InvalidAuthTokenError();
            }
        }
        throw new errors_1.ResponseError(err.response, err);
    }
    else {
        throw err;
    }
});
const changeUser = (u) => {
    if (u) {
        user = u;
    }
    else if (user) {
        user = undefined;
        storage.set(Cookies.user);
        exports.sessionEvents.fire('logout');
    }
};
exports.initSession = async () => {
    if (!exports.config.mobile)
        throw Error('initSession can only be called on mobile devices');
    if (exports.config.debug)
        console.log('initSession()');
    const result = await parseAxiosResponse(axios_1.default.post(exports.config.serverURL + '/session', Object.assign(Object.assign({}, exports.config.mobile), { api_revision: exports.API_REVISION })));
    if (result.config) {
        if (exports.config.debug)
            console.log('initSession returns config', result.config);
        if (result.config.serverURL && result.config.serverURL !== exports.config.serverURL) {
            Object.assign(exports.config, result.config);
            storage.set(Cookies.token);
            storage.set(Cookies.user);
            return await exports.initSession();
        }
        else {
            Object.assign(exports.config, result.config);
        }
    }
    if (exports.config.debug)
        console.log('initSession() finished, updating tokens and firing auth event');
    changeUser(result.user);
    storage.set(Cookies.token, result.token);
    exports.http.processPendingAuth();
    exports.sessionEvents.fire('authenticated');
    return result;
};
const sessionLogin = async (email, password, linkDevice = false) => exports.http.post(exports.config.serverURL + '/session/login', {
    email, password,
    link_device: linkDevice,
});
const webLogin = async (email, password) => parseAxiosResponse(axios_1.default.post(exports.config.serverURL + '/login', {
    email, password,
}));
exports.login = async (email, password, linkDevice) => {
    const result = exports.config.mobile
        ? await sessionLogin(email, password, linkDevice)
        : await webLogin(email, password);
    storage.set(Cookies.token, result.token);
    storage.set(Cookies.user, JSON.stringify(result.user));
    user = result.user;
    exports.http.processPendingAuth();
    exports.sessionEvents.fire('authenticated');
    return result;
};
exports.logout = () => {
    user = undefined;
    storage.set(Cookies.token);
    storage.set(Cookies.user);
    exports.sessionEvents.fire('logout');
};
exports.recoverPassword = async (email) => exports.http.post(`${exports.config.serverURL}/forgotten_password`, { email });
exports.changePassword = async (token, new_password) => parseAxiosResponse(axios_1.default.post(`${exports.config.serverURL}/password_reset`, { new_password }, {
    responseType: 'text',
    headers: { Authorization: 'Bearer ' + token },
}));
exports.getRoles = () => { if (user)
    return user.roles; };
;

import React from 'react';
import styled from 'styled-components';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useValidation, ValidableFieldProps } from './validation';

export const SWTextField = ({ field, validations, helperText, validateWith, label, ...props }: ValidableFieldProps & TextFieldProps) => {
  const { error, value, updateField, isRequired } = useValidation({ field, validations, validateWith });
  const onUpdate = (ev) => updateField(field, ev.target.value);
  if (isRequired) label += ' *';
  const inputLabelProps = props.InputLabelProps || {};
  if (props.type === 'date') {
    inputLabelProps.shrink = true;
  }
  return (
    <TextField
      label={label}
      value={value || ''}
      error={!!error}
      helperText={error || helperText}
      onChange={onUpdate}
      InputLabelProps={inputLabelProps}
      {...props}
    />
  );
};

/**
 * Disabled TextField styled for click and modal opening instead of writing.
 */
export const DummyTextField = styled(TextField) <{ warn?: boolean; }>`
  .MuiInputBase-root, input {
    caret-color: transparent;
    cursor: pointer;
  }
  .MuiInput-underline::before {
    ${({ warn }) => warn ? 'border-bottom: 2px solid #af6703;' : ''}
  }
`;

import React from 'react';
import styled from 'styled-components';
import { ButtonProps, Button, CircularProgress } from '@material-ui/core';

const StyledCircular = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.spacing(3)}px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const SWButton = ({ disabled, loading, ...props }: ButtonProps & { loading?: boolean; }) =>
  <StyledButton
    {...props}
    disabled={disabled || loading}
  >
    {loading && <StyledCircular size={26} />}
    {props.children}
  </StyledButton>;
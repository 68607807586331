"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userService = void 0;
const app_service_1 = require("./app.service");
const CachedEntityService_1 = require("./generic/CachedEntityService");
const EntityService_1 = require("./generic/EntityService");
exports.userService = new CachedEntityService_1.CachedEntityService(new EntityService_1.EntityService('users'));
const updateUser = exports.userService.update.bind(exports.userService);
exports.userService.update = async (key, data, params) => {
    var _a;
    const result = await updateUser(key, data, params);
    if (key === ((_a = app_service_1.getUser()) === null || _a === void 0 ? void 0 : _a.id)) {
        app_service_1.setUser(result);
        app_service_1.sessionEvents.fire('authenticated');
    }
    return result;
};
/*
export const userService = Object.assign(
  {},
  _userService,
  {
    update(key: number, data: IUser) {
      const result = _userService.update(key, data);
      if (key === getUser()?.id) {
        result.then(setUser);
        sessionEvents.fire('authenticated');
      }
    }
  }
);
*/
/*
export const userService = {
  ..._userService,
  update(key: number, data: IUser) {
    const result = _userService.update(key, data);
    if (key === getUser()?.id) {
      result.then(setUser);
      sessionEvents.fire('authenticated');
    }
  }
};
*/ 

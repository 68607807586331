"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    bovinae: {
        name: 'Bovino',
        generic: 'Conxunto mestizo',
        galega: 'Rubia galega',
        av_n_iberica: 'Avileña-Negra Ibérica',
        av_n_ib_bocib: 'Avileña-Negra Ibérica (Bociblanca)',
        cachena: 'Cachena',
        limia: 'Limiá',
        caldela: 'Caldelá',
        frieiresa: 'Frieiresa',
        vianesa: 'Vianesa',
        limusina: 'Limusina',
        frisona: 'Frisona',
        parda: 'Parda',
        pasiega: 'Pasiega',
        chaloresa: 'Chaloresa',
        blon_aqui: 'Blonda de Aquitania',
        fleckvieh: 'Fleckvieh',
        ast_vall: 'Asturiana de los Valles',
        lidia: 'Lidia',
        morucha: 'Morucha',
        morucha_negra: 'Morucha negra',
        pard_mont: 'Parda de la Montaña',
        pirenaica: 'Pirenaica',
        retinta: 'Retinta',
        albera: 'Albera',
        alis_sanab: 'Alistan-Sanabresa',
        ast_mont: 'Asturiana de la Montaña',
        berre_col: 'Berrenda en Colorado',
        berre_neg: 'Berrenda en Negro',
        betizu: 'Betizu',
        blan_cace: 'Blanca Cacereña',
        brun_piri: 'Bruna de los Pirineos',
        canaria: 'Canaria',
        card_and: 'Cárdena Andaluza',
        mallorqui: 'Mallorquina',
        marismeña: 'Marismeña',
        menorquina: 'Menorquina',
        monchina: 'Monchina',
        murc_levan: 'Murciana-Levantina',
        neg_and: 'Negra Andaluza',
        pajuna: 'Pajuna',
        pallaresa: 'Pallaresa',
        palmera: 'Palmera',
        sayaguesa: 'Sayaguesa',
        serra_neg: 'Serrana Negra',
        serra_ter: 'Serrana de Teruel',
        terreña: 'Terreña',
        tudanca: 'Tudanca',
    },
    // Ovellas
    caprinae_ovis: {
        name: 'Ovino',
        generic: 'Conxunto mestizo',
        ove_gal: 'Ovella Galega',
        carranzana: 'Carranzana',
        carranza_negra: 'Carranzana Negra',
        castellana: 'Castellana',
        castell_negra: 'Castellana Negra',
        churra: 'Churra',
        latxa: 'Latxa',
        merina: 'Merina',
        merina_negra: 'Merina Negra',
        merina_m_univ: 'Merina Montes Universales',
        manchega: 'Manchega',
        manchega_negra: 'Manchega Negra',
        navarra: 'Navarra',
        oji_ter: 'Ojinegra de Teruel',
        rasa_arag: 'Rasa Aragonesa',
        segureña: 'Segureña',
        berr_cher: 'Berrinchon du Cher',
        charmoise: 'Charmoise',
        fleischsch: 'Fleischschaf',
        ile_fran: 'Ile de France',
        landschaff: 'Landschaff',
        mer_pre: 'Merino Precoz',
        alcarreña: 'Alcarreña',
        ansotana: 'Ansotana',
        aranesa: 'Aranesa',
        canaria: 'Canaria',
        cana_pel: 'Canaria de Pelo',
        cartera: 'Cartera',
        chamarita: 'Chamarita',
        chu_leb: 'Churra Lebrijana',
        chu_ten: 'Churra Tensina',
        colmenare: 'Colmenareña',
        guirra: 'Guirra',
        lojeña: 'Lojeña',
        maellana: 'Maellana',
        mer_gra: 'Merina de Grazalema',
        montesina: 'Montesina',
        ojalada: 'Ojalada',
        ov_eivi: 'Ovella Eivissenca',
        ov_mayor: 'Ovella Mayorquina',
        ov_menor: 'Ovella Menorquina',
        ov_roj_may: 'Ovella Roja Mayorquina',
        palmera: 'Palmera',
        ripollesa: 'Ripollesa',
        roya_bilbi: 'Roya Bilbilitana',
        rub_mol: 'Rubia del Molar',
        sasi_ardi: 'Sasi Ardi',
        talaverana: 'Talaverana',
        xalda: 'Xalda',
        xisqueta: 'Xisqueta',
        lacaune: 'Lacaune',
        assaf: 'Assaf',
        salz: 'Salz',
        texel: 'Texel',
        // Engadidas 2020 07
        frisona: 'Frisona',
        INRA401: 'Romane (INRA401)',
        blanche: 'Blanche du Massif Central',
        leicester: 'Border Leicester',
        causses: 'Causses du Lot',
        charollais: 'Charollais',
        corse: 'Corse',
        dorper: 'Dorper',
        hampshire: 'Hampshire',
        jacob: 'Jacob',
        katahdin: '(borrego) Katahdin',
        tete_rousse: 'Lecheras de los Pirineos / Tête Rousse',
        limousine: 'Limousine',
        lincoln: 'Lincoln',
        loaghtan: 'Loaghtan manés',
        merino_arles: 'Mérinos d’Arles',
        prealpes: 'Préalpes du Sud',
        rava_noire: 'Rava-Noire du Velay-Grivette-Bizet',
        romanov: 'Romanov',
        romney: 'Romney',
        rouge: 'Rouge de l’Ouest',
        suffolk: 'Suffolk',
        santa_ines: 'Santa Inés',
        tarasconnaise: 'Tarasconnaise',
        vendeen: 'Vendéen',
    },
    // Cabras
    caprinae_capra: {
        name: 'Caprino',
        generic: 'Conxunto mestizo',
        cab_gal: 'Cabra Galega',
        florida: 'Florida',
        malagueña: 'Malagueña',
        murc_grana: 'Murciano-Granadina',
        alpina: 'Alpina',
        azp_gor: 'Azpi Gorri',
        bla_and_se: 'Blanca Andaluza o Serrana',
        bla_cel: 'Blanca Celtibérica',
        bla_ras: 'Blanca de Rasqueta',
        bermeya: 'Bermeya',
        cab_mes: 'Cabra de las Mesetas',
        de_guada: 'Del Guadarrama',
        eivissenca: 'Eivissenca',
        majorera: 'Majorera',
        mallorquina: 'Mallorquina',
        moncaina: 'Moncaína',
        neg_serra: 'Negra Serrana',
        palmera: 'Palmera',
        payoya: 'Payoya',
        pirenaica: 'Pirenaica',
        retinta: 'Retinta',
        tinerfeña: 'Tinerfeña',
        verata: 'Verata',
    },
    //porcos
    suidae: {
        name: 'Porcino',
        generic: 'Conxunto mestizo',
        porco_celta: 'Porco Celta',
        iberico: 'Ibérico',
        ib_entrepela: 'Ibérico Entrepelado',
        ib_retinto: 'Ibérico Retinto',
        ib_lampino: 'lampiño',
        ib_manchado_jb: 'Ibérico Manchado de Jabuco',
        ib_torbiscal: 'Ibérico Torbiscal',
        bla_bel: 'Blanco Belga',
        duroc: 'Duroc',
        hampshire: 'Hampshire',
        landrace: 'Landrace',
        lar_whi: 'Large White',
        pietrain: 'Pietrain',
        cha_mur: 'Chato Murciano',
        eus_txe: 'Euskal Txerria',
        goc_ast: 'Gochu Asturcelta',
        ner_can: 'Negra Canaria',
        por_neg_ma: 'Porc Negre Mallorquí',
    },
    // asnos e burros
    equinae_asinus: {
        name: 'Equino (Asnos)',
        generic: 'Conxunto mestizo',
        andaluza: 'Andaluza',
        ase_bal: 'Ase Balear',
        as_enca: 'Asno de las Encartaciones',
        catalana: 'Catalana',
        majorera: 'Majorera',
        zam_leo: 'Zamorano-Leonés',
    },
    // cabalos
    equinae_ferus: {
        name: 'Equino',
        generic: 'Conxunto mestizo',
        cab_pur_ga: 'Cabalo de Pura Raza Galega',
        española: 'Española',
        ang_ara: 'Anglo-Árabe',
        arabe: 'Árabe',
        pur_sa_ing: 'Pura Sangre Inglés',
        trotador: 'Trotador',
        asturcon: 'Asturcón',
        burguete: 'Burguete',
        cab_ret: 'Caballo de las Retuertas',
        cab_mon_vas: 'Caballo de Monte de País Vasco',
        cav_may: 'Cavall Mallorquí',
        cav_men: 'Cavall Menorquí',
        cav_piri: 'Cavall Pirinenc Català',
        his_ara: 'Hispano-Árabe',
        his_bre: 'Hispano-Bretón',
        jac_nav: 'Jaca Navarra',
        losina: 'Losina',
        marismeña: 'Marismeña',
        monchina: 'Monchina',
        pottoka: 'Pottoka',
    },
    equinae_mulus: {
        name: 'Equino (Mulos/Mulas',
        generic: 'Conxunto mestizo',
    },
    aves: {
        name: 'Avícola',
        generic_gal: 'Gallina/Gallo',
        gal_mos: 'Galiña de Mos',
        comb_esp: 'Combatiente Español',
        and_azu: 'Andaluza Azul',
        eus_ant: 'Euskal Antzara',
        eus_oil: 'Euskal Oiloa',
        gal_cas_ne: 'Gallina Castellana Negra',
        gal_eivi: 'Gallina Eivissenca',
        gal_empor: 'Gallina Empordanesa',
        gal_ext_az: 'Gallina Extremeña Azul',
        gal_pra: 'Gallina del Prat',
        gal_ped: 'Gallina Pedresa',
        gal_sob: 'Gallina del Sobrarbe',
        ind_leo: 'Indio de León',
        mallorquina: 'Mallorquina',
        menorquina: 'Menorquina',
        murciana: 'Murciana',
        oca_emp: 'Oca Empordanesa',
        par_leo: 'Pardo de León',
        penedesen: 'Penedesenca',
        pit_pint: 'Pita Pinta',
        utrerana: 'Utrerana',
        val_chu: 'Valenciana de Chulilla',
    }
};

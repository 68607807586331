"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCattleUnitOrBatchLabel = exports.cattleDiseases = exports.cattleBatchesService = exports.cattleSpecies = exports.birthService = exports.cattleUnitsService = exports.cattleTreatmentsService = exports.reproductionService = void 0;
const moment = require("moment");
const lodash_1 = require("lodash");
const app_service_1 = require("./app.service");
const EntityService_1 = require("./generic/EntityService");
const ExploitationEntityService_1 = require("./generic/ExploitationEntityService");
const MappedEntityService_1 = require("./generic/MappedEntityService");
const dataMappers_1 = require("../utils/dataMappers");
const translations_1 = require("../utils/translations");
exports.reproductionService = new ExploitationEntityService_1.ExploitationEntityService('cattle/reproduction');
exports.cattleTreatmentsService = new ExploitationEntityService_1.ExploitationEntityService('cattle/treatments');
const cattleUnitMapper = async (u) => {
    if (!u.specie_id)
        return u;
    const specie = await exports.cattleSpecies.get(u.specie_id);
    return Object.assign(Object.assign({}, u), { specie_code: specie === null || specie === void 0 ? void 0 : specie.specie, subspecie_code: specie === null || specie === void 0 ? void 0 : specie.subspecie });
};
const _cattleUnitService = new MappedEntityService_1.MappedExploitationEntityService('cattle/units', cattleUnitMapper);
exports.cattleUnitsService = Object.assign(_cattleUnitService, {
    async get(expId, unitId) {
        const units = await _cattleUnitService.list(expId);
        return units.find(u => u.id === unitId);
    },
    getLabel(unit) {
        if (!unit)
            return '';
        return unit.name
            ? unit.eartag
                ? `${unit.name} (${unit.eartag})`
                : unit.name
            : unit.eartag || moment(unit.birth).format('DD/MM/YYYY');
    },
});
const [birthMapper, birthMapperR] = dataMappers_1.genBothMappers({
    nested: { calves: { divide: { weight: 10000 } } },
});
exports.birthService = new MappedEntityService_1.MappedExploitationEntityService('cattle/births', birthMapper, birthMapperR, () => {
    _cattleUnitService.clearCache();
});
let cattleSpeciesCache;
exports.cattleSpecies = {
    async list() {
        if (!cattleSpeciesCache) {
            const url = `${app_service_1.config.serverURL}/cattle/species`;
            cattleSpeciesCache = app_service_1.http.get(url)
                .then(list => list
                .map(({ id, code }) => ({ id, specie: code.split(':')[0], subspecie: code.split(':')[1] })));
            cattleSpeciesCache = await cattleSpeciesCache;
        }
        return cattleSpeciesCache;
    },
    async get(id) {
        const species = await this.list();
        return species.find(s => s.id === id);
    },
    async groups() {
        const species = await this.list();
        return Array.from(new Set(species.map(s => s.specie)));
    },
};
const _cattleBatchesService = new ExploitationEntityService_1.ExploitationEntityService('cattle/batches');
const _cattleBatchesCattleUnitService = new EntityService_1.EntityService('exploitations/:exploitationId/cattle/batches/:batchId/units');
exports.cattleBatchesService = {
    getLabel: (batch) => batch ? batch.name || `(${batch.id}` : '',
    list(exploitationId) {
        return _cattleBatchesService.list(exploitationId);
    },
    delete(exploitationId, batchId) {
        return _cattleBatchesService.delete(exploitationId, batchId);
    },
    async get(exploitationId, batchId) {
        const list = await this.list(exploitationId);
        return list.find(b => b.id === batchId);
    },
    async save(exploitationId, batch) {
        const old = batch.id
            ? (await _cattleBatchesService.list(exploitationId)).find(o => o.id === batch.id)
            : undefined;
        // TODO: make transactional with API work
        if (batch.id) {
            await _cattleBatchesService.update(exploitationId, batch.id, batch);
        }
        else {
            const created = await _cattleBatchesService.create(exploitationId, batch);
            batch.id = created.id;
        }
        if (batch.items) {
            // Remove deleted
            if (old) {
                const oldIds = (old.items || []).map(p => p.id);
                const newIds = batch.items.map(p => p.id).filter(lodash_1.identity);
                await Promise.all(oldIds
                    .filter(oi => oi && !newIds.includes(oi))
                    .map(deletedId => _cattleBatchesCattleUnitService.delete(deletedId, { exploitationId, batchId: batch.id })));
            }
            await Promise.all(batch.items.map(c => c.id
                ? _cattleBatchesCattleUnitService.update(c.id, c, { exploitationId, batchId: batch.id })
                : _cattleBatchesCattleUnitService.create(c, { exploitationId, batchId: batch.id })));
        }
    },
};
let cattleDiseasesCache;
exports.cattleDiseases = {
    async list() {
        if (!cattleDiseasesCache) {
            const url = `${app_service_1.config.serverURL}/cattle/diseases`;
            cattleDiseasesCache = app_service_1.http.get(url);
            cattleDiseasesCache = await cattleDiseasesCache;
        }
        return cattleDiseasesCache;
    },
};
exports.getCattleUnitOrBatchLabel = async ({ exploitationId, unitId, batchId }) => {
    if (!exploitationId)
        throw new Error('Missing exploitationId');
    if (unitId && batchId)
        throw new Error('Both unit and batch are set');
    if (unitId) {
        const unit = await exports.cattleUnitsService.get(exploitationId, unitId);
        const label = exports.cattleUnitsService.getLabel(unit);
        return `(${translations_1.t('notebook.cattleunit')}) ${label}}`;
    }
    else if (batchId) {
        const batch = await exports.cattleBatchesService.get(exploitationId, batchId);
        const label = exports.cattleBatchesService.getLabel(batch);
        return `(${translations_1.t('notebook.batch')}) ${label}`;
    }
    else
        return '';
};

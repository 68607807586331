import { t } from '@smartwood/common-client/utils/translations';
import { validateFiscalId } from '@smartwood/common-client/utils/fiscalIds.js';
import { useContext } from 'react';
import { FormContextType, FormContext } from './Form';
import { toISOShort } from '../../utils/date';

/**
 * Each validator returns error text or undefined/null/false if valid
 */
export const validators = {
  required: value => (value == null || value === '') && t('validations.required'),
  minimum: min => value => !!value && (Number(value) < min) && t('validations.min', { min }),
  maxLength: (max: number) => (value: any) => value != null && value.length > max && t('validations.maxLength', { max }),
  length: (l: number) => (value: any) => value != null && ('' + value).length !== l && t('validations.length', { length: l }),
  noFuture: d => d > toISOShort(new Date()) && t('validations.noFuture'),
  email: (v: string) => {
    if (v == null || v === '') return false;
    const chunks = v.split('@');
    if (chunks.length !== 2 || !chunks[1].includes('.') || chunks[1].endsWith('.'))
      return t('validations.email');
  },
  valid_fiscal_id: (value) => !!value && !validateFiscalId(value) && t('validations.invalid_fiscal_id'),
};

export type ValidatorName = 'required';

export const validate = (chain: (ValidatorName | Validator)[], value: any, model: any = {}) => {
  if (!chain) return;
  for (let validator of chain) {
    if (typeof validator === 'string')
      validator = validators[validator];
    const error = (validator as Validator)(value, model);
    if (error) return error;
  }
};

export const useValidation = ({ field, validations = [], validateWith }: ValidableFieldProps) => {
  const { errors, model, registerField, updateField } = useContext<FormContextType>(FormContext);
  registerField(field, validations, validateWith);
  return {
    error: errors[field],
    value: model[field],
    isRequired: validations.includes('required') || validations.includes(validators.required),
    updateField
  };
};

export interface ValidableFieldProps {
  field: string;
  validations?: ValidationChain;
  validateWith?: string[];
}

export type Validator = (v: any, model: any) => undefined | null | false | string | { [nested: string]: string; };
export type ValidationChain = (ValidatorName | Validator)[];

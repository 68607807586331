import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormControlLabel, Checkbox, CheckboxProps } from '@material-ui/core';
import { FormContextType, FormContext } from './Form';

const StyledControlLabel = styled(FormControlLabel)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  margin-left: 0px;
`;

export const StyledCheckBox = ({ label, ...props }: CheckboxProps & { label?: string; }) => (
  <StyledControlLabel
    control={<Checkbox {...props} />}
    label={label}
  />
);

export const SWCheckBox = ({ label, field }: { label: string, field: string; }) => {
  const { model, updateField } = useContext<FormContextType>(FormContext);
  return <StyledControlLabel
    control={<Checkbox checked={!!model[field]} onChange={ev => updateField(field, ev.target.checked)} />}
    label={label}
  />;
};
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const admin = {
    adminPanel: 'Panel de administrador',
    sections: {
        users: 'Usuarios'
    },
    users: {
        new: 'Nuevo Usuario',
        edit: 'Editar Usuario',
        title: 'Usuarios',
        name: 'Nombre',
        surname: 'Apellidos',
        phone: 'Teléfono',
        mobile_phone: 'Teléfono móvil',
        email: 'Correo Electrónico',
        role: 'Rol',
        roles: {
            title: 'Roles',
            addRole: 'Añadir rol',
            admin: 'Administrador',
            user: 'Usuario'
        }
    }
};
exports.default = admin;

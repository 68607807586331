"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.t = exports.getTranslationsData = exports.initTranslations = void 0;
let lang;
const interpolationRegex = /{([^}]+)}/g;
exports.initTranslations = (data) => lang = data;
exports.getTranslationsData = (lang, custom = {}) => {
    return require(`../translations/${lang}`).getTranslations(custom);
};
exports.t = (key, data) => {
    if (!lang)
        throw Error('Init language data before translating');
    const txt = interpolation(getTranslationRaw(key), data);
    return txt;
};
const getTranslationRaw = (key) => {
    let data = lang;
    const chunks = key.split('.');
    const fallback = chunks[chunks.length - 1];
    for (let chunk of chunks) {
        const node = data[chunk];
        if (!node) {
            console.log('Missing translation for', { key, chunk });
            return fallback;
        }
        data = node;
    }
    return data;
};
const interpolation = (template, data) => {
    if (!data)
        return template;
    return template.replace(interpolationRegex, (_match, param) => {
        if (data[param] == null) {
            console.error('Missing translation value for interpolation', { param, template, data });
            return param;
        }
        // TODO correct print for dates or other datatypes
        return data[param];
    });
};

import { withRouter, useLocation } from 'react-router-dom';
import { getUser } from '@smartwood/common-client/services/app.service';

const pathParamRegex = /\/:([^/]+\??)/g;
export const buildPath = (path: string, params: any) =>
  path.replace(pathParamRegex, (_match: string, param: string) => {
    const optional = param.endsWith('?');
    if (optional) {
      param = param.slice(0, -1);
      if (params[param] == null) {
        return '';
      }
    } else if (params[param] == null) {
      throw Error(`Missing path parameter ${param}`);
    }
    return '/' + params[param];
  });

export const AuthRoute = withRouter(({ history, children }) => {
  if (!getUser()) {
    const query = new URLSearchParams({ returnUrl: window.location.href });
    setImmediate(() => history.replace('/?' + query));
    return false;
  }
  return children;
});

export const parseQuery = (location) => new URLSearchParams(location.search);
export const useQuery = () => parseQuery(useLocation());

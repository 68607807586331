import React from 'react';
import { useValidation, ValidableFieldProps } from './validation';
import { TextField, TextFieldProps } from '@material-ui/core';

export const NumberField = ({ value, maxDecimals, onChange, ...props }: NumberFieldProps) => {
  const stringValue = value != null
    ? value.toString()
    : '';
  return (
    <TextField
      {...props}
      value={stringValue}
      type='number'
      onChange={(ev) => {
        const v = ev.target.value;
        if (v === '') {
          onChange(null);
        } else {
          const n = Number(v);
          if (v.endsWith('.') && value === n) {
            // Skip, the value is already updated and this will lead to lose our point
            return;
          }
          if (Number.isNaN(n)) onChange(value);
          else onChange(n);
        }
      }}
      onKeyPress={(e) => {
        const input = e.target as HTMLInputElement;
        const k = e.key;
        if (['.', ','].includes(k)) {
          if (input.value.includes('.') || input.value.includes(',') || maxDecimals === 0) {
            e.preventDefault();
          }
        } else if (isNaN(Number(e.key))) {
          e.preventDefault();
          return;
        } else if (maxDecimals != null
          && input.value.includes('.')
          && input.value.indexOf('.') < (input.value.length - maxDecimals)) {
          e.preventDefault();
        }
      }}
    />
  );
};


export const SWNumberField = ({ field, validations, helperText, maxDecimals, validateWith, label, ...props }: ValidableFieldProps & Omit<NumberFieldProps, 'onChange'>) => {
  const { error, value, updateField, isRequired } = useValidation({ field, validations, validateWith });
  const stringValue = value != null
    ? value.toString()
    : '';
  if (isRequired) label += ' *';

  return (
    <NumberField
      value={stringValue}
      label={label}
      type='number'
      error={!!error}
      helperText={error || helperText}
      onChange={v => updateField(field, v)}
      {...props}
    />
  );
};

type NumberFieldProps = TextFieldProps & {
  value?: number;
  maxDecimals?: number;
  onChange: (v: any) => any;
};

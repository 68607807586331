import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { t } from '@smartwood/common-client/utils/translations';
import { validate, ValidationChain } from './validation';

export const FormContext = React.createContext({} as FormContextType);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  &>* {
    margin: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const SWForm = (props: SWFormProps) => {
  const [errors, setErrors] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const pendingValidation = useRef<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const fields: { [field: string]: ValidationChain; } = {};
  const validateRelations: { [field: string]: string[]; } = {};

  const validateField = (field: string, v: any) =>
    setErrors({ ...errors, [field]: validate(fields[field], v, props.model) });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const related = pendingValidation.current.pop();
      if (related) {
        validateField(related, props.model[related]);
      }
    }, 100);
    return () => clearTimeout(timeout);
  });

  const registerField = (field: string, validations: ValidationChain, validateWith: string[] = []) => {
    fields[field] = validations;
    validateRelations[field] = validateWith;
  };
  const updateField = (field: string, value: any) => {
    validateField(field, value);
    // Mutating the prop is ugly, but this way we can preserve consecutive changes if called several times in a row
    // Maybe we should use a ref for that
    props.model[field] = value;
    props.setModel(props.model);
    if (validateRelations[field]) {
      pendingValidation.current.push(...validateRelations[field]);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (hasErrors(errors)) {
      enqueueSnackbar(t('app.errors.formHasErrors'), { variant: 'error' });
      return;
    }
    const validationResults = Object.entries(fields)
      .reduce((e, [field, validators]) => {
        const errors = validate(validators, props.model[field], props.model);
        return errors ? { ...e, [field]: errors } : e;
      }, {});
    if (!hasErrors(validationResults)) {
      try {
        setSaving(true);
        await props.onSubmit(props.model);
        setSaving(false);
      } catch (e) {
        console.error(e);
        throw e;
      }
    } else {
      setErrors(validationResults);
      enqueueSnackbar(t('app.errors.formHasErrors'), { variant: 'error' });
    }
    //TODO: display alert?
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <FormContext.Provider value={{ registerField, updateField, errors, setErrors, model: props.model, saving }}>
        {props.children}
      </FormContext.Provider>
    </StyledForm>
  );
};

/**
 * Looks for non null/undefined error values, iterating arrays and objects
 * @param errors errors object
 */
const hasErrors = (errors: any) => {
  if (errors == null) return false;
  if (Array.isArray(errors)) return errors.some(hasErrors);
  if (typeof errors === 'object') return Object.values(errors).some(hasErrors);
  return true;
};

export interface FormContextType {
  errors: any;
  setErrors: (e: any) => any;
  model: { [field: string]: any; };
  registerField: RegisterFieldFn;
  updateField: UpdateFieldFn;
  saving: boolean;
}

type RegisterFieldFn = (field: string, validations: ValidationChain, validateWith?: string[]) => void;
type UpdateFieldFn = (field: string, value: any) => void;

type SWFormProps = {
  children: React.ReactNode,
  model: { [field: string]: any; },
  onSubmit: (data: any) => any,
  setModel: (data: any) => void,
};

export { validators } from './validation';
export { SWTextField } from './TextField';
export { SWNumberField } from './NumberField';
export { SWFormActions } from './FormActions';
export { SWCheckBox } from './CheckBox';
export { ShrinkSpecieField } from './ShrinkSpecieField';
export { SWSelect } from './Select';
export { SWNestedSelect } from './SWNestedSelect';
export { CattleSpecieField, SWCattleSpecieField } from './CattleSpecieField';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormControl, Input, InputLabel, FormHelperText, MenuItem, CircularProgress, Popover } from '@material-ui/core';

export const NestedSelect = ({ data, value, onChange, error, label, helperText }: NestedSelectProps) => {
  const anchorEl = useRef<HTMLElement>();
  const [path, setPath] = useState<NestedSelectItem[]>([]);
  const [levelData, setLevelData] = useState<NestedSelectItem[] | undefined>();
  const [filteredData, setFilteredData] = useState<NestedSelectItem[] | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const changePath = useCallback((v: NestedSelectItem[]) => {
    setPath(v);
    setLevelData(undefined);
    setFilteredData(undefined);
    setFilter('');
  }, [setPath, setLevelData]);
  const chooseItem = useCallback(async (item: NestedSelectItem) => {
    changePath([...path, item]);
  }, [changePath, path]);
  useEffect(() => {
    if (!levelData && open) {
      let cleaned = false;
      const parent = path[path.length - 1];
      getData(data, parent).then(d => {
        if (cleaned) return;
        if (!d || d.length === 0) {
          setOpen(false);
          setLevelData(undefined);
          setFilteredData(undefined);
          setFilter('');
          setPath([]);
          onChange(parent);
        } else {
          setLevelData(d);
        }
      }).catch(console.error);
      return () => { cleaned = true; };
    }
  }, [path, levelData, open, data, setOpen, setLevelData, setFilter, onChange]);
  const doFilter = debounce(() => {
    setFilteredData(levelData && levelData.filter(d =>
      d.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
    );
  }, 200);
  const id = `nestedselect-${label}`;
  return <>
    <FormControl onClick={() => setOpen(true)}>
      <InputLabel error={!!error} htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        ref={anchorEl}
        value={filter || value?.label || ''}
        error={!!error}
        onChange={ev => {
          if (!open) return;
          setFilter(ev.target.value);
          doFilter();
        }}
        endAdornment={<StyledArrowDropDownIcon />}
      />
      {helperText && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
    </FormControl>
    {open && <Popover
      open
      style={{
        width: anchorEl.current ? anchorEl.current.clientWidth : undefined,
        zIndex: 1400,
      }}
      role="presentation"
      onClose={() => setOpen(false)}
      anchorEl={anchorEl.current}
    >
      {renderPath(path, changePath)}
      {!levelData && <CircularProgress />}
      {levelData && renderLevel(filteredData || levelData, chooseItem)}
    </Popover>}
  </>;
};

const getData = async (data, parent?: NestedSelectItem) => {
  if (parent?.children) {
    return await parent.children;
  }
  if (typeof data === 'function') {
    data = data(parent);
  }
  if (parent?.children) {
    return parent.children;
  }
  return await data;
};

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  cursor: pointer;
`;

const renderPath = (path: NestedSelectItem[], setPath) =>
  path.map((item, i) =>
    <MenuItem
      key={'back-' + i}
      onClick={() => setPath(path.splice(0, i))}
    >
      <ArrowBackIcon />
      {item.label}
    </MenuItem>);

const renderLevel = (items: NestedSelectItem[], chooseItem) =>
  items.map((item, i) =>
    <MenuItem
      key={'item-' + i}
      onClick={() => chooseItem(item)}
    >
      {item.label}
    </MenuItem>
  );


export type NestedSelectProps = {
  value?: NestedSelectItem;
  onChange: (v: NestedSelectItem) => void;
  error?: string | boolean;
  helperText?: string;
  label: string;
  data: NestedSelectData | ((key?: any) => NestedSelectData);
};

export type NestedSelectData = NestedSelectItem[] | Promise<NestedSelectItem[]>;

interface NestedSelectItem {
  value: any;
  label: string;
  children?: NestedSelectData;
}
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { SWHeader } from './Header';

const Content = styled.div`
  padding-top: ${({ theme }) => theme.layout.titleHeight};
  height: calc(100% - ${({ theme }) => theme.layout.titleHeight});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: rgb(67,193,151);
  background: linear-gradient(135deg, rgba(44,119,108,1) 0%, rgba(5,5,14,1) 100%);
`;

export const BaseLayout = ({ children }) =>
  <Fragment>
    <SWHeader />
    <Content>
      {children}
    </Content>
  </Fragment>;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genBothMappers = exports.genReverseMapperFn = exports.genMapperFn = void 0;
const cloneDeep = require('clone-deep');
exports.genMapperFn = ({ divide, nested }, clone = true) => {
    const transformers = [];
    if (divide)
        Object.entries(divide).forEach(([key, divider]) => transformers.push((d) => d[key] != null && (d[key] /= divider)));
    if (nested)
        Object.entries(nested).forEach(([key, transformations]) => {
            const innerFn = exports.genMapperFn(transformations, false);
            transformers.push((d) => Array.isArray(d[key])
                ? d[key].map(innerFn)
                : innerFn(d[key]));
        });
    return (data) => {
        if (typeof data !== 'object')
            return data;
        const result = clone ? cloneDeep(data) : data;
        transformers.forEach(t => t(result));
        return result;
    };
};
exports.genReverseMapperFn = ({ divide, nested }, clone = true) => {
    const transformers = [];
    if (divide)
        Object.entries(divide).forEach(([key, divider]) => transformers.push((d) => {
            if (!Number.isFinite(d[key]))
                return;
            d[key] = Math.round(d[key] * divider);
        }));
    if (nested)
        Object.entries(nested).forEach(([key, transformations]) => {
            const innerFn = exports.genReverseMapperFn(transformations, false);
            transformers.push((d) => Array.isArray(d[key])
                ? d[key].map(innerFn)
                : innerFn(d[key]));
        });
    return (data) => {
        if (typeof data !== 'object')
            return data;
        const result = clone ? cloneDeep(data) : data;
        transformers.forEach(t => t(result));
        return result;
    };
};
exports.genBothMappers = (transformations) => [exports.genMapperFn(transformations), exports.genReverseMapperFn(transformations)];

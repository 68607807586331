import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { initTranslations } from '@smartwood/common-client/utils/translations';
import { init } from '@smartwood/common-client/services/app.service';

const serverURL = process.env.NODE_ENV && process.env.NODE_ENV === 'development'
  ? 'https://dev.smartwood.app/api/v1'
  : '/api/v1';
init({ serverURL },
  {
    get(key: string) {
      return localStorage.getItem(key);
    },
    set(key: string, val: string) {
      if (val == null) localStorage.removeItem(key);
      else localStorage.setItem(key, val);
    }
  }
);

import(/* webpackChunkName: "es" */ './i18n/es').then(lang => {
  initTranslations(lang.default);
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});

import React from 'react';
import { LoginForm } from '../LoginForm';
import { modalMessages, ModalMessageOptions } from '../../utils/modals';
import { sessionEvents } from '@smartwood/common-client/services/app.service';
import { Modal } from '../generic/Modal';

export class GlobalAlerts extends React.Component<{}, GlobalAlertsState> {

  private onModalMsg;
  private onSessionExpired;

  constructor(props) {
    super(props);
    this.state = {
      modal: undefined
    };
  }

  componentDidMount() {
    this.onModalMsg = modalMessages.on(modal => this.setState({ modal }));
    this.onSessionExpired = sessionEvents.on(ev => {
      if (ev === 'expired') this.setState({
        modal: {
          Content: () => <LoginForm onLogin={() => this.setState({ modal: undefined })} />
        }
      });
    });
  }

  componentWillUnmount() {
    modalMessages.disable(this.onModalMsg);
    sessionEvents.disable(this.onSessionExpired);
  }

  render() {
    const ModalContent = this.state.modal?.Content;
    return (
      <Modal open={!!ModalContent}>
        {ModalContent && <ModalContent />}
      </Modal>
    );
  }

}

interface GlobalAlertsState {
  modal?: ModalMessageOptions;
}